/*Login Styles*/
/*Login Styles*/

.login-content-container {
  position: relative;
  min-height: 100vh;
  background-color: #f4f4f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Euclid', Poppins, Roboto, sans-serif;

  .MuiInputBase-input {
    height: 2rem;
    margin-left: 8px;
  }

  .MuiFormLabel-root {
    margin-left: 8px;
  }

  .MuiTextField-root {
    background: white;
  }
}

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 30px;
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(4px);
  font-size: 14px;
  max-width: 94%;

  @media screen and (max-width: 575px) {
    padding: 25px 25px 20px;
  }
}

.login-header {
  margin-bottom: 30px;

  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
}

.login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.app-login-main-content {
  @include display-flex();
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(10px);
  font-size: 14px;
  overflow: scroll;
}

.app-login-content {
  padding: 35px 35px 20px;
  width: 76%;
  order: 1;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 2;
  }
}

.app-login-header {
  margin-bottom: 30px;
}

.app-login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.app-login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-logo-content {
  background-color: $app-primary;
  padding: 35px 35px 20px;
  width: 40%;
  order: 2;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 1;
  }
}

.app-social-block {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    border: solid 1px $app-primary;
    @include border-radius(50%);
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary;
    }
  }

  & p {
    margin-bottom: 0;
  }
}

.login-form {
  form > :last-child {
    margin-bottom: 0;
  }
}

.hr-text-center {
  display: table;
  width: 100%;
  font-size: 1em;
  margin: 0 auto;
  color: #000;
}

.hr-item {
  display: table-cell;
}

.hr-text {
  white-space: nowrap;
  width: 1%;
  padding: 0 6px;
}

.hr-line {
  border-bottom: 1.23px solid rgba(0, 0, 0, 0.1);
  position: relative;
  top: -0.6em;
}

.register-login-link {
  margin-top: 50px;
  font-size: 0.867rem;
  color: map_get($gray, darken-1);
}

.login-register-banner {
  font-family: 'Euclid Bold', Poppins, Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 10px;
}
.signup-title-small {
  font-family: 'Euclid Bold', Poppins, Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.7rem;
  color: #000000;
  margin-bottom: 7px;
}
.appsumo-img{
  width: 500px;
  padding-left: 100px;
  padding-bottom: 50px;
}

@media (max-width: 790px) {
  .login-register-banner {
    font-size: 30px;
    line-height: 30px;
  }

  .login-register-lower-banner {
    font-size: 18px;
    line-height: 30px;
  }
}

.login-register-button {
  .MuiButton-root {
    font-size: 1.5rem;
    text-align: center;
    text-transform: none;
    height: 50px;
    background: #2296f3;
    border: 1.23px solid rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 6.17px;
    font-size: 18.51px;
    color: #ffffff;
    font-family: 'Euclid', Poppins, Roboto, sans-serif;
  }
  p {
    text-align: left;
    a {
      margin-left: 4px;
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }
}

.google-signin-register-login {
  height: 50px;
  margin-bottom: 10px;
  border: 1.23px solid rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(40px) !important;
  border-radius: 6.17px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  font-family: 'Euclid', Poppins, Roboto, sans-serif !important;
  span {
    font-size: 18.51px !important;
    font-weight: normal !important;
    color: #101010 !important;
  }
  div {
    background-color: transparent !important;
  }
}

.login-register-lower-banner {
  font-weight: normal;
  font-size: 15px;
  color: #000000;
}

.login-register-image-container {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: url('/images/app/images/stock.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bottom-right-banner {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  height: 159px;
  width: 100%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.26);
  background: #0f1e33;
  align-items: center;
}

.bottom-right-banner-image-sec {
  width: 143px;
  height: 143px;
  background: url('/images/app/images/fingerprint.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 20px;
}

.text-container-bottom-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}

.right-base-text-header {
  .MuiTypography-root {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.right-base-text-normal {
  .MuiTypography-root {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
  }
}

@media (max-width: 820px) {
  .right-base-text-normal {
    .MuiTypography-root {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .right-base-text-header {
    .MuiTypography-root {
      font-size: 17px;
      line-height: 20px;
    }
  }
}

@media (max-width: 600px) {
  .reset-password-button {
    .MuiButton-root {
      font-size: 16px;
      height: 65px;
    }
  }
}

.login-forgot-password-text {
  font-family: 'Euclid';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #545454;
  margin-top: 5px;
  &:hover,
  &:active {
    color: #545454;
    text-decoration: underline;
  }
}

.sent-reset-password {
  font-size: 20px;
  color: #0cc20c;
}

@media (max-width: 800px) {
  .sent-reset-password {
    font-size: 16px;
  }
}



.notif-body {
  margin-bottom: 2rem;

  .template {

    background: #FFFFFF;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08), 4px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 461.51px;
    height: 67.02px;
    position: relative;




    &::before {
      position: absolute;
      content: '';
      left: .2rem;
      top: 10%;
      width: 4.17px;
      height: 56.18px;
      background: #4CAF50;
      border-radius: 5px;

    }

    .template-body {
      display: flex;
      padding: .8rem;
      justify-content: flex-start;
      align-items: center;

      .text {
        font-family: 'Euclid';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;

        color: #7A7A7A;

        .bolder {
          font-weight: bolder;
          font-size: 17px;
        }
      }

      .tick-icon {
        margin: 0 1rem 1rem 0;
      }

    }

    .close-icon {
      position: absolute;
      right: 6;
      top: 0;
      cursor: pointer;
    }
  }
}