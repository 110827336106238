%shared-profile-style-card {
  @include display-flex(flex);
  align-items: center;
  font-family: Roboto;
}

.profile-info-boby {
  min-height: 160px;
}

.profile-lg-card-container {
  .user-detail-card {
    .jr-card-body {
      padding-top: 5px;
      padding-bottom: 0px;
    }
    .user-img-container {
      width: 162px;
      height: 161px;
      left: -62px;
    }
  }
}

.no-contacts {
  margin: 50px 0 12vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin: 1rem 0 2rem 0;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }
}

.contact-details-card {
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .enrich-data-header{
    background-color: #2e4665;
    color: white;
    height: 10px;
    .enrich-data-header-item{
      font-weight: bolder;
      text-transform: capitalize;
    }
  }

  table {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid rgb(209, 209, 209);
        th {
          white-space: nowrap;
          text-align: center;
          padding:1px 10px;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid rgb(209, 209, 209);
        td {
          padding:3px 10px;
          text-align: center;
          // text-wrap: nowrap;
          .hubspot-button {
            border: 1px solid #2296f3;
            border-radius: 2px;
            width: 110px;
            height: 30px;
            text-transform: none;
            background: white;
            color: #2296f3;
            font-size: 12px;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition-duration: 100ms;
            &:hover {
              // box-shadow: 0 12px 10px -13px #2296f3;
              transform: scale(1.01);
            }
            &.success {
              background: #f7761f;
              border: 1px solid #f7761f;
              color: white;
              gap: 5px;
            }
            &.fail {
              background: #e74c3c;
              border: 1px solid #e74c3c;
              color: white;
              gap: 5px;
            }
          }

          

          .profile-handle-icon {
            width: 21px;
            height: 21px;
            margin-bottom: 3px;
            img {
              width: 100%;
              border-radius: 3px;
              margin-right: 20px;
            }
          }

       

          .decision-maker {
            width: 81px;
            height: 18px;
            background: rgba(18, 18, 18, 0.7);
            border-radius: 5px;

            font-style: normal;
            font-weight: 500;
            font-size: 9px;
            white-space: nowrap;

            display: inline-flex;
            align-items: center;
            justify-content: center;

            color: #ffffff;
            margin: auto;

            & + span {
              width: 59px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.profile-title-card {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4f4f4f;
  margin-bottom: 2px;
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-names-card {
  @extend %shared-profile-style-card;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  margin-bottom: 5px;
}

.profile-email-number-card {
  @extend %shared-profile-style-card;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2d9cdb;
  margin-bottom: 2px;
  margin-right: 2px;
}

.profile-handle-card {
  width: 100%;
  min-height: 30px;
  @extend %shared-profile-style-card;
  justify-content: flex-end;
  img {
    margin-left: 5px;
  }
}

.profile-protractor-text {
  width: 99%;
  @include multiLineEllipsis(1.2em, 3, transparent);
}

.profile-activity-funnel {
  width: 100%;
}

.profile-activity-graph {
  box-sizing: border-box;
  width: 100%;
  min-height: 400px;
  max-height: 400px;
}

.profile-activity-tab {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1077px) {
    flex-direction: column-reverse;
    .profile-activity-funnel {
      width: 100%;
    }
    .profile-activity-graph {
      box-sizing: border-box;
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }
  }
}

.upgrade-button {
  font-size: 11px;
  color: #1E4EFF;
  border: 1px solid #1E4EFF;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 50px;
  padding: 0 2px;
  width: 160px;
  height: 25px;
  border-radius: 4px;
  background-color: white !important;
  line-height: normal;
  transition: box-shadow 0.3s ease;
}

.upgrade-button:hover {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.padlock-icon {
  margin-right: 4px;
  color: black!important;
}

.padlock-icon svg {
  font-size: 15px!important;
}

.profile-contact-verify {
  font-size: 11px;
  color: #fff;
  background-color: #1E4EFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: 59px;
  height: 25px;
  border-radius: 4px;
  margin-bottom: 3px;
  cursor: pointer;
}
.profile-contact-verify-new {
  font-size: 11px;
  color: #1E4EFF;
  border:1px solid #1E4EFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  padding: 0 2px;
  width: 160px;
  height: 25px;
  border-radius: 4px;
  margin-bottom: 3px;
  cursor: pointer;
}
.profile-contact-verify-loader {
  font-size: 11px;  
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  padding: 0 2px;
  width: 160px;
  height: 25px;
  border-radius: 4px;
  margin-bottom: 3px;  
  .loader-text {
    margin-left: 10px;
  }
  
  .loader-text-bold {
    font-weight: bolder;
  }
  
}

.profile-contact-catch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f2994a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verify-email-catch {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #f2994a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-contact-valid {
  @extend .profile-contact-catch;
  background: #07bb0c;
}
.verify-email-valid {
  @extend .verify-email-catch;
  background: #07bb0c;
}
.profile-contact-invalid {
  @extend .profile-contact-catch;
  background: #e74c3c;
}
.verify-email-invalid {
  @extend .verify-email-catch;
  background: #e74c3c;
}
.verify-email-risky {
  @extend .verify-email-catch;
  background: #BD00FF;
}
.verify-email-progress {
  @extend .verify-email-catch;
  background: #fcb93d;
}
.skipped-email {
  @extend .verify-email-catch;
  background: #151514;
}
.invalidStyle {
  color: #e74c3c;
}

.funnelView-slider-container {
  margin-top: 17px;
  width: 95%;
  max-width: 393px;
  height: 12px;
  background: #3e7cbb;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  box-sizing: border-box;
  padding-left: 5.5%;
  padding-right: 5.5%;
  @media (max-width: 1000px) {
    padding-right: 7%;
    padding-left: 7%;
  }
  .rc-slider-step {
    border-radius: 3px;
    height: 4px;
    background: #3e7cbb;
  }
  .rc-slider-handle {
    width: 44px;
    height: 13px;
    border: none;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
  }
}
.lead-slider-handle {
  height: 13px;
  width: 44px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.profile-news {
  margin-top: 30px;
  width: 60%;
  @media (max-width: 1200px) {
    width: 100%;
  }
  &-item {
    margin-bottom: 50px;
    a {
      font-weight: 300;
      font-size: 1.125rem;
      line-height: 21px;
      color: #0064ce;
    }
    p {
      font-size: 0.875rem;
      line-height: 21px;
      color: #696969;
      margin: 16px 0;
    }
    footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 0.875rem;
        line-height: 21px;
        color: #666666;
        margin: 0;
      }
      small {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 21px;
        color: #666666;
      }
    }
  }
}
//Profile App Style
.jr-profile-banner {
  padding: 30px;
  padding-bottom: 82px;
  margin: -30px;
  margin-bottom: -55px;
  //  background-image:url("/images/app/images/profile-background.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  position: relative;

  @media screen and (max-width: 575px) {
    padding: 20px 15px;
    padding-bottom: 77px;
    margin: -20px;
    margin-bottom: -58px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 1)
    );
  }

  & > div {
    position: relative;
    z-index: 5;
  }

  & .jr-link {
    color: $white;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $secondary;
    }
  }
}

.jr-profile-banner-top {
  @include display-flex(flex, row, wrap);
  @include align-items(center);
  margin-bottom: 20px;

  @media (max-width: 575px) {
    @include display-flex(flex, column, nowrap);
    margin-bottom: 20px;
  }
}

.jr-profile-banner-top-left {
  @include display-flex(flex, row, wrap);
  @include align-items(center);

  @media (max-width: 575px) {
    @include display-flex(flex, column, nowrap);
    margin-bottom: 15px;
  }
}

.jr-profile-banner-avatar {
  margin-right: 1.5rem;

  @media (max-width: 575px) {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.jr-profile-banner-top-right {
  text-align: center;
  margin-left: auto;

  @media (max-width: 575px) {
    margin-left: 0;
  }
}

.jr-profile-banner-bottom {
  @include display-flex(flex, row, wrap);
  @include align-items(center);

  @media (max-width: 575px) {
    @include display-flex(flex, column, nowrap);

    .jr-tab-list {
      margin-bottom: 10px;
    }
  }
}

.jr-profile-content {
  position: relative;
  z-index: 2;
}

.jr-profile-setting {
  margin-left: auto;

  @media (max-width: 575px) {
    margin-left: 0;
  }
}

.jr-profile-content-right {
  @media (max-width: 575px) {
    @include display-flex(flex, row, wrap);
    @include align-items(center);

    .text-truncate {
      width: auto;
      margin-left: auto;
    }
  }
}

.jr-pro-contact-list {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.jr-card-profile {
  position: relative;

  &-sm {
    position: relative;
  }

  & .card-header {
    background-color: transparent;
    border-bottom: 0 none;
    padding: 20px $jr-card-padding;

    @media (max-width: 575px) {
      border-bottom: solid 1px $border-color;
      flex-wrap: wrap;
    }
  }

  & .card-title {
    font-size: 18px;
    text-transform: capitalize;
  }

  & .jr-tabs-up {
    margin-top: -50px;

    @media (max-width: 575px) {
      margin-top: 0;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  & .jr-tabs-content {
    padding: 10px $jr-card-padding 20px;
  }
}

.technology-chip {
  .MuiChip-root {
    border-radius: 5px;
  }
}


.email-contact {
  margin-bottom: 4px;
  cursor: pointer;
}

.list_campaigns{
    padding: 10px 15px;
      font-size: 0.8rem;
      color: black;
      border-radius: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      &:hover {
        background: rgba(#2296f3, 0.1);
      }
      &.activeDrop {
        background: rgba(#2296f3, 0.4);
      }
  
}
.list_hubspot{
    padding: 10px 15px;
      font-size: 0.8rem;
      color: black;
      // background: #fafafa;

      // border-radius: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      border-bottom: 1px solid #000000;
  // background-color: white !important;
  // padding: 1rem;
}
.poper{
  // width:10rem;
  border-radius: 5px;
  // height: 2rem;
  // padding: .6rem;

  
 
  z-index: 20000000000;
  background-color: #ffffff;
  // position: absolute;
}
.list_campaign{
  background-color: white !important;
}
.list_export{
  background-color: white !important;
}
.list_export_btn{
  white-space: nowrap;
  padding: 10px 15px;
      font-size: 0.8rem;
      color: black;
      background: #fafafa;      

      // border-radius: 5px;
      margin-bottom: 5px;
      width: 100%;
      border:none;
      outline:none;
      cursor: pointer;
      &:hover {
        background: #0c00ff;
        color:white;
      }
      &.activeDrop {
        background: #0c00ff;
        color:white;
      }
      &:disabled{
        background: #e0e0e0;
        cursor: not-allowed;
      }
}
// Most of the component styles here are not stable change them carefully
.lead-email {
  // width: 250px !important;
  cursor: pointer;
  
}

.lead-email-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.normal-flex{
  display: grid;
  grid-template-columns: 95% 12px; 
  gap: 4px;
  align-items: center;
  justify-content: center;
  
}
.lead-handles{
  margin-left: -90px;
}

.lead-seniority-header{
  margin-left: 100px;
}

.contact-name {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  gap: 10px;
}
.parent-table-row td{
  width: 200px !important;
}
.contact-persona{
  width: 30px !important;
  // width: 300px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // background-color: red !important;
}
.contact-info-buttons{
  // background-color: red !important;
  width: 100px;
  display: block;
}

.just-scraped {
  width: 81px;
  height: 18px;
  background: #004bff;
  border-radius: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  white-space: nowrap;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  margin: auto;

  & + span {
    width: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loadingSpinner {
  width: 60px;
  height: 60px;
  border: 11px solid #f3f3f3;
  border-top: 11px solid #0c00ff; 
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinnerContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 650px;
} 