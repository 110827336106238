/*Header Styles*/
$app-primary: #1e43ff;
.jr-menu-icon {
  outline: none !important;
  @include justify-content(flex-start !important);
  @extend %size-30;
  padding: 0 5px !important;
  @extend %jr-menu-icon;
  margin-right: 15px;
}

.app-header-container {
  .MuiToolbar-root {
    background-color: $app-primary !important;
    background: $app-primary !important;
  }
}

.app-main-header {
  //z-index: 1302 !important;
  background-color: $app-primary !important;
  color: $white !important;
  .list-inline li {
    padding: 0px !important;
  }

  @media screen and (min-width: 600px) {
    .MuiToolbar-gutters {
      padding-left: 24px;
      padding-right: 0px;
    }
  }

  .ellipse-shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
    height: 100%;
    width: 560px;

    &:after {
      content: '';
      display: block;
      width: 870px;
      position: absolute;
      top: -20px;
      right: -300px;
      height: 280px;
      z-index: 1;
      //background-color: rgba($white, 0.06);
      @include border-radius(50% 0 0 50%);
    }
  }

  & .search-bar input {
    @include transition($transition-base);
    background-color: rgba($white, 0.2);
    color: $white;
    & + .search-icon {
      top: 0;
      & i {
        color: rgba($white, 0.8);
      }
    }

    &:focus {
      background-color: $white;
      color: $body-color;
      + .search-icon {
        & i {
          color: rgba($body-color, 0.8);
        }
      }
    }
  }
}

.app-logo img {
  height: 50px;
  @media screen and (max-width: 575px) {
    height: 28px;
  }
}
.app-logo-sm{
  font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
  
    color: #FFFFFF;
    margin-left: 6px;
}

.app-toolbar-special {
  & .search-bar {
    width: 200px;

    @media screen and (max-width: 991px) {
      width: 280px;
    }

    @media screen and (max-width: 767px) {
      width: 200px;
    }
  }
}

.app-toolbar {
  position: relative;
  z-index: 2;
  min-height: $app-bar-height !important;
  height: $app-bar-height;
  max-height: $app-bar-height;

  & .search-bar {
    margin-left: 50px;
    margin-right: auto;
    max-width: 300px;
    width: 280px;

    @media screen and (max-width: 767px) {
      margin-left: 40px;
      width: 200px;
    }
  }
}

.app-header-horizontal .app-toolbar {
  & .search-bar {
    margin-left: 20px;
    max-width: 320px;
    width: 300px;

    @media screen and (max-width: 1199px) {
      width: 240px;
      margin-right: 10px;
    }

    @media screen and (max-width: 1099px) {
      width: 170px;
    }

    @media screen and (max-width: 767px) {
      margin-left: 40px;
      width: 180px;
    }
  }
}

.app-toolbar {
  & .nav-searchbox {
    & .search-bar {
      margin-left: 0;
      width: 100%;
    }

    & .search-bar input {
      @include transition($transition-base);
      background-color: $white;
      color: $body-color;
      & + .search-icon {
        & i {
          color: rgba($body-color, 0.8);
        }
      }

      &:focus {
        background-color: transparent;
        color: $body-color;
        + .search-icon {
          & i {
            color: rgba($body-color, 0.8);
          }
        }
      }
    }
  }
}

.header-notifications {
  margin-bottom: 0;

  & li {
    vertical-align: middle;

    &.list-inline-item:not(:last-child) {
      margin-right: 0;
    }

    .app-header-horizontal & {
      @media screen and (max-width: 991px) {
        padding: 0 2px;
      }
    }
  }

  & .app-notification {
    &-item {
      padding: 2px 50px 2px 8px;
      cursor: pointer;
      border-radius: 0px;
      height: 50px;
      border-left: 1px solid #0156b0;
      @include display-flex();
      @include align-items(center);
      &:hover {
        background-color: rgba($black, 0.15);
      }
    }
    &-menu {
      margin-right: 10px;
      .header-menu-text {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 21px;
        color: white;
      }

      .header-menu-icon {
        @media screen and (min-width: 992px) {
          display: none;
        }
      }

      & i + span {
        margin-left: 4px;

        @media screen and (max-width: 991px) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 991px) {
      &-item {
        padding: 0 16px;
      }
    }

    & .dropdown-menu {
      width: 210px;
    }

    & .jr-list-link {
      @include display-flex(flex, column, nowrap);
      @include align-items(center);
      @include transition(all 0.5s ease);
      @include border-radius($border-radius);
      padding: 10px;
      color: $gray-600;
      border: 1px solid transparent;

      @include hover-focus-active {
        color: $app-primary;
        border-color: $app-primary;
        text-decoration: none;
      }

      & > i {
        font-size: 22px;
        & + .jr-list-text {
          margin-top: 8px;
        }
      }
    }
  }
}

.quick-menu {
  // & .dropdown-menu {
  //   padding: 5px 10px;
  //   width: 350px;
  //   border-radius: 0px !important;
  //   & .jr-card-header {
  //     margin: -5px -10px 5px;
  //     padding: 10px 10px 10px 20px;
  //     border-bottom: 1px solid $gray-300;
  //     z-index: 10;

  //     & .icon-btn:last-child {
  //       margin-top: 0;
  //       margin-right: 0;
  //     }
  //   }

  //   &:after {
  //     border-left: 5px solid transparent;
  //     border-right: 5px solid transparent;
  //     border-bottom: 5px solid $white;
  //     top: 10px;
  //     content: "";
  //     height: 9px;
  //     position: absolute;
  //     right: 8px;
  //     width: 10px;
  //   }

  //   &:before {
  //     border-left: 5px solid transparent;
  //     border-right: 5px solid transparent;
  //     //border-bottom: 5px solid $black;
  //     top: 10px;
  //     content: "";
  //     height: 10px;
  //     position: absolute;
  //     right: 8px;
  //     width: 10px;
  //   }

  //   @media screen and (max-width: 449px) {
  //     width: 280px;
  //   }

  //   @media screen and (max-width: 359px) {
  //     width: 240px;
  //   }
  // }

  & .messages-list .user-avatar {
    position: relative;
  }

  & .messages-list .badge {
    position: absolute;
    left: -4px;
    top: -4px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    text-align: center;
    padding: 0;
  }
}

@media screen and (max-width: 575px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 200px;
  }
}

@media screen and (max-width: 399px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 100px;
  }
}

.messages-list.language-list ul li {
  padding: 7px 6px 6px;
}

.messages-list ul li {
  position: relative;
  padding: 20px 10px 14px;
  border-bottom: 1px solid $gray-300;

  & .user-name a {
    color: $app-primary;
  }

  & .sub-heading {
    font-size: 11px;
    margin-bottom: 6px !important;

    &.mb-0 {
      margin-bottom: 0 !important;
    }
  }

  &:last-child {
    border-bottom: 0 none;
  }

  & .jr-btn {
    margin-right: 0 !important;
  }

  & .jr-btn-xs {
    font-size: 11px !important;
    padding: 5px !important;
  }
}

.quick-menu {
  & .icon-btn {
    color: $white;
    font-size: 20px !important;
    padding: 6px;
  }

  & .icon-alert {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      z-index: 10;
      width: 8px;
      height: 8px;
      background-color: $danger;
      @include border-radius(50%);
      @extend %pulse-effect;
    }
  }

  & i.animated.infinite {
    -moz-animation-iteration-count: 50;
    -webkit-animation-iteration-count: 50;
    -o-animation-iteration-count: 50;
    animation-iteration-count: 50;
  }
}

.quick-menu {
  & .dropdown-menu {
    display: block;
    visibility: hidden;
    left: auto !important;
    right: -5px !important;
    opacity: 0;
    min-width: 250px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);
    transform: translate3d(0px, 100px, 0px) !important;
    @include transition(all 200ms linear 0ms);
  }

  &.show .dropdown-menu {
    opacity: 1;
    transform: translate3d(-4px, 67px, 0px) !important;
    visibility: visible;
  }
}

.user-nav .quick-menu {
  & .dropdown-menu {
    width: 220px;
    min-width: 250px;
    padding: 0 0 15px;

    &:after {
      border-bottom-color: $body-bg;
    }
  }

  & .user-profile {
    position: relative;
    padding: 20px 20px;
    background-color: $body-bg;
    //@include border-radius(0.25rem 0.25rem 0 0);
    @include display-flex();
    border-radius: 0px !important;

    & .user-avatar {
      margin-right: 0;
    }
  }
}

.dropdown-item[class*='text-'] {
  &.active,
  &:active {
    background-color: transparent;
  }
}

.popup-progress-warning {
  .MuiLinearProgress-barColorPrimary {
    background-color: #ffc71e !important;
  }
}

.popup-progress-exuasted {
  .MuiLinearProgress-barColorPrimary {
    background-color: #ee4444 !important;
  }
}

.quota-progress-zero {
  .MuiLinearProgress-barColorPrimary {
    background-color: rgba(255, 255, 255, 0) !important;
  }
}

.quota-progress-empty {
  .MuiLinearProgress-barColorPrimary {
    background-color: #fff !important;
  }
}

.quota-progress-less {
  .MuiLinearProgress-barColorPrimary {
    background-color: #E86E6E !important;
  }
}

.quota-progress-middle {
  .MuiLinearProgress-barColorPrimary {
    background-color: #FFAF55 !important;
  }
}

.quota-progress-almost {
  .MuiLinearProgress-barColorPrimary {
    background-color: #02C36F !important;
  }
}
.quota-progress-enough {
  .MuiLinearProgress-barColorPrimary {
    background-color: #2296f3 !important;
  }
}

.rotate-dropdown-arrow-header {
  .app-notification-menu {
    &:after {
      transform: rotate(180deg);
      top: 35%;
    }
    .zmdi-chevron-down {
      font-size: 20px;
    }
  }
}

.app-notification-item {
  .zmdi-chevron-down {
    font-size: 24px;
  }
}

.app-notification-menu {
  .zmdi-help-outline {
    font-size: 18px;
  }
}

.help-dropdown {
  padding: 5px 0 !important;
  width: 200px !important;
  @media screen and (max-width: 380px) {
    min-width: 123px !important;
    width: auto !important;
  }
  &::after,
  &::before {
    display: none;
  }
  &__menu {
    ul {
      margin: 0;
      padding: 0;
      text-indent: 0;
      list-style-type: none;
      li {
        a,
        span {
          padding: 5px 10px !important;
          display: block;

          color: #575757 !important;
          text-decoration: none;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.39998px;

          display: flex;
          align-items: center;
          gap: 5px;
          svg {
            font-size: 16px;
          }
        }
        &:hover,
        &:focus,
        &:active {
          background: #002aff;
          a,
          span {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}

.header-notifications-trial {
  p {
    font-size: 0.875rem;
    span {
      font-weight: 700;
      color: #ffcc00;
    }
  }

  @media screen and (max-width: 650px) {
    display: none !important;
  }
}

.no-top-radius {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
