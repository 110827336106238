.form-wrapper-invite {
  width: 100%;
}

.ant-form-invite {
  box-sizing: border-box;
  padding: 5px;
  padding-bottom: 20px;
  width: 100%;
  margin: 0px;
  min-height: 400px;
  .ant-form-item {
    margin: 0 0 10px;
  }
  .ant-input-group {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    .input-invite-item {
      margin-right: 1.5em;
      @media only screen and (max-width: 770px) {
        margin-right: 0.7em;
        margin-left: 0.7em;
      }
    }

    .close-invite-item {
      @media only screen and (max-width: 770px) {
        margin-right: 0.7em;
        margin-left: 0.7em;
      }
    }

    .select-invite-item {
      margin-right: 1em;
      @media only screen and (max-width: 770px) {
        margin-right: 0.7em;
        margin-left: 0.7em;
      }
    }
    .add-new-row-invite {
      margin-top: 20px;
      @media only screen and (max-width: 770px) {
        margin-top: 0 !important;
      }
    }
    @media only screen and (max-width: 770px) {
      padding-top: 0px;
    }
    .ant-input {
      width: 238.7px;
      @media only screen and (max-width: 1295px) {
        width: 205px;
      }
      @media only screen and (max-width: 1154px) {
        width: 172px;
      }
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    border-radius: 5px;
    width: 238.7px;
    @media only screen and (max-width: 1295px) {
      width: 205px;
    }
    @media only screen and (max-width: 1154px) {
      width: 172px;
    }
  }
  @media only screen and (max-width: 770px) {
    padding-top: 10px;
    padding-bottom: 5px;
    .ant-input-group {
      display: table;
      .ant-input {
        width: 100%;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
    }
  }
}

.spinner-invite-submit {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.invite-member-text {
  margin: 0 auto;
  max-width: 900px !important;
  .MuiTypography-body1 {
    @media (max-width: 600px) {
      font-size: 0.867rem;
    }
  }
}

.invite-button {
  border-radius: 4px;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  background-color: #2296f3;
  border-color: #2296f3;
  font-size: larger;
  color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.26);
  width: 178px;
  height: 48px;
  &:hover:enabled {
    background-color: #1560bd !important;
    background: #1560bd !important;
    border-color: #1560bd !important;
  }
  @media only screen and (max-width: 650px) {
    //
  }
}

.secondary-banner-invite {
  .MuiTypography-body1 {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    color: #485a6f;
    text-align: center;
    @media only screen and (max-width: 650px) {
      font-size: 1rem;
      line-height: normal;
    }
  }
}

.input-invite {
  border-radius: 5px;
  background: #f9fafb;
  margin-bottom: 2px;
  height: 50px;
}

.invite-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #9e9e9e;
  max-width: 355px;
  margin-left: auto;
  margin-right: auto;
}
