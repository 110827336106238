/*Leads Page Styles*/

.app-leads-tabs {
  // position: relative;
  @media (min-width: 600px) {
    background-color: map_get($gray, lighten-5);
    .MuiTab-root {
      min-width: 50%;
    }
  }

  .MuiTab-root {
    padding: 6px 12px;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
    &:enabled {
      color: map_get($light-blue, darken-3);
    }
    max-width: 50%;
    min-width: 50%;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: none;
  }
}
.app-prospector-tabs {
  @media (min-width: 600px) {
    background-color: map_get($gray, lighten-5);
    .MuiTab-root {
      min-width: 50%;
    }
  }
  .MuiTab-root {
    padding: 6px 12px;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;

    &:enabled {
      color: map_get($light-blue, darken-3);
    }

    max-width: 50%;
    min-width: 50%;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: 'Roboto',
    'Helvetica',
    'Arial',
    sans-serif;
    font-weight: 400;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: none;
  }
}

.leads-select-form-control {
  margin: 8px;
  margin-left: auto;
  min-width: 200px;
}

.app-exportCSV-container {
  @media (max-width: 604px) {
    width: 100%;
    .MuiButton-root {
      width: 99%;
    }
  }
}

.app-leads-button {
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  .MuiButton-root {
    text-transform: none;
    font-size: 0.875rem;
  }
  @media (max-width: 604px) {
    margin-left: 0px;
    .MuiButton-root {
      width: 99%;
    }
  }
}

 .app-leads-button:disabled{
  background-color: #BFBFBF !important;
 }

.app-download-button {
  margin-left: 2px;
  min-height: 2.5rem;

 

  .MuiButton-root {
    text-transform: none;
    font-size: 0.875rem;
  }
  @media (max-width: 604px) {
    margin-left: 0px;
    .MuiButton-root {
      width: 99%;
    }
  }
}

.app-leads-control-bottons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .MuiButton-root {
    text-transform: none;
    font-size: 0.875rem;
  }
  @media (max-width: 680px) {
    flex-direction: column-reverse;
    .MuiButton-root {
      width: 99%;
    }
  }
}

.app-leads-select-container {
  padding: 4px;
  .MuiInputBase-root {
    font-size: 0.875rem;
  }
}

.app-leads-logo-images {
  width: 35px;
  height: 100%;
  border-radius: 15%;
  font-size: 0.7rem !important;
  object-fit: cover;
}

.app-leads-trending-up-icon {
  .MuiIconButton-root {
    size: 20px;
    color: map_get($green, accent-3);
    font-size: 0.875rem;
    &:hover {
      background-color: transparent;
    }
  }
}

.app-leads-trending-down-icon {
  .MuiIconButton-root {
    size: 20px;
    color: map_get($deep-orange, accent-4);
    font-size: 0.875rem;
    &:hover {
      background-color: transparent;
    }
  }
}

@media (min-width: 600px) {
  .app-leads-table-container {
    .MuiToolbar-regular {
      min-height: 10px;
    }
  }
}

.app-leads-table-container {
  // width: '100%';
  .MuiToolbar-regular {
    min-height: 10px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: map_get($light-blue, darken-3);
  }
  .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background-color: map_get($blue, lighten-5);
  }
 
}
.app-prospects-table-container {
  // width: '100%';
  height: 658px !important;
  scrollbar-width: 5px; 
  
    &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: rgba(#323232, 0.5);
            border-radius: 10px;
        }
  .MuiToolbar-regular {
    min-height: 10px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: map_get($light-blue, darken-3);
  }
  .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background-color: map_get($blue, lighten-5);
  }
 
}

.app-leads-tables-tags {
  padding: 5px;
  min-width: 45px;
  font-size: 0.675rem;
  border-radius: 15px;
  color: white;
  min-height: 21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.app-leads-tables-tags-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-leads-tables-edit-icon {
  .MuiIconButton-root {
    size: 16px;
    font-size: 0.775rem;
    &:hover {
      background-color: transparent;
    }
  }
}

.app-leads-table-preview-button {
  min-height: 45px;
  .MuiButton-root {
    font-size: 0.875rem;
    text-transform: none;
    height: 45px;
  }
}

.app-leads-tab-slide-container {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  // padding-left: 0px !important;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
      background-color: rgba(#323232, 0.5);
      border-radius: 10px;
      cursor: pointer;
  }

}

.leads-tab-slide-container {
  height: 75vh;
  padding-left: 8px !important;
}
.prospector-tab-slide-container {
  height: 84vh;
  padding-left: 8px !important;
}
.app-leads-slider {
  position: relative;
  width: 100%;
  height: 40;
  border: 0px;
  background: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.app-rule-border-bottom {
  background: rgb(193, 204, 221);
  width: 100%;
  height: 0.2px;
}

.app-leads-accordion-header {
  .MuiTypography-root {
    font-size: 0.875rem;
    font-weight: 400;
  }
}
.app-prospector-accordion-header {
  .MuiTypography-root {
  font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
  
  
    color: #000000;
  }
}

.app-leads-filters-tag-dot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}
//new select
.app-leads-fetch-select {
  width: 150px;
}

.app-leads-daterangepicker-container {
  position: relative;
  display: block;
  margin-top: 4px;
  .DateRangePicker {
    display: block;
  }
}

.app-leads-daterangepicker {
  position: absolute;
  background: white;
  margin-bottom: 20px;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1.5px 1.5px 2.5px 3px map_get($gray, lighten-1);
  .DateRangePicker__CalendarSelection {
    background-color: map_get($blue, lighten-1);
    border: 1px solid map_get($blue, lighten-1);
  }
}

.app-leads-fetch-select-options {
  border-bottom: 4px;
  border-bottom-color: map_get($gray, lighten-1);
  display: block;
  margin-bottom: 2px;
}

.app-leads-error {
  color: map_get($red, darken-1);
  font-size: 0.876rem;
}

.app-leads-filters-dialog-action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.app-leads-no-scores {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.app-leads-saved-filters-button {
  .MuiButton-root {
    text-transform: none;
    font-size: 0.875rem;
  }
}

.app-leads-display-saved-filters {
  // color: map_get($gray, darken-1);
  color: #000;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-leads-saved-filters-icon {
  color: map_get($blue, darken-4);
  align-self: center;
  font-size: 20px;
}

.app-leads-no-filters-found {
  font-size: 0.876rem;
  color: map_get($gray, darken-2);
  box-sizing: border-box;
  margin: 10px;
  margin-top: 30px;
}

.app-leads-saved-filters-container {
  cursor: pointer;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  @include display-flex(flex, column, nowrap);
  .app-leads-delete-filter {
    display: none;
  }
}
.prospector-saved-filters-container{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(#e8e5e5, 0.5);
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(#323232, 0.5);
    border-radius: 10px !important;
  }
}

.app-leads-saved-filters-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

.app-leads-delete-filter {
  .MuiSvgIcon-root {
    font-size: 1.1rem;
    color: map_get($gray, darken-1);
  }
}

.app-saved-filter-row {
  padding-left: 38px;
  padding-right: 15px;
  height: 30px;
  &:hover {
    background-color: #f4f4f7;
    background: #f4f4f7;
    .app-leads-delete-filter {
      display: inline-block;
    }
  }
}

.app-leads-table-no-search-result {
  height: calc(100vh - 170px) !important;
  width: 99%;
  margin: 0 5px 5px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.1rem;
  color: map_get($gray, darken-2);  
}

.app-leads-online {
  color: map_get($green, darken-2);
}

.signup-signin-app-logo img {
  height: 70px;

  @media screen and (max-width: 575px) {
    height: 50px;
  }
}

.leads-url-text {
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #4f4f4f;
  margin-bottom: 2px;
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leads-button-tag-root {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  border-radius: 13px;
  min-height: 21px;
  justify-content: center;
  align-items: center;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 45px;
  margin-left: 6px;
  cursor: pointer;
  text-align: center;
  &:hover {
    .leads-button-tag-normal {
      display: none;
    }

    .leads-button-tag-delete {
      display: flex;
    }
  }
}

.leads-button-tag-normal {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  align-items: center;
  text-align: center;
  padding-top: 1.2px;
}

.leads-button-tag-delete {
  position: relative;
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 10px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 11px;
  align-items: center;
  text-align: center;
}

.leads-table-row {
  .MuiTableCell-root {
    border-bottom: none !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  &:hover {
    .leads-add-tag-icon {
      visibility: visible;
    }
  }
}
.leads-table-row-shadow {
  &:hover {
    .leads-add-tag-icon {
      visibility: visible;
    }
  }
  margin-bottom: 14px 0 !important;
  box-shadow: 4px 4px 4px -3px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05) !important;
}

.leads-add-tag-icon {
  margin-left: 6px;
  display: inline;
  visibility: hidden;
}

.leads-tags-select-text {
  text-transform: uppercase;
  font-size: 12px;
}

.filters-leads-skeleton {
  width: 100%;
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: '6px';
}

.lead-profile-tag-button {
  font-size: 12.5px !important;
}
.prospector-accordions{  
  overflow-x: hidden;
  overflow-y: scroll;
&::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(#e8e5e5, 0.5);
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(#323232, 0.5);
    border-radius: 10px !important;
  }
}
.leads-saved-filter-text {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  line-height: 24.5px;
  color: #000;
  margin-left: 1.4px;
  width: 100%;
  margin-top: 15px;
  text-align: center;
  background: white;
  .prospector-companies-found{
    font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      font-variant: small-caps;
      display: block;    
      color: #63CC6D;
  }
}

.leads-clear-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #fff;
  // margin-left: 2px !important;
  position: absolute;  
  bottom: 0;
  border-top: 1px solid #ededed;
  align-items: center;
  z-index: 3000;
  width: 100%;
  height: 66px;
}
.prospector-clear-container {
  display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #fff;
    padding-right: 10px;
    position: absolute;
    bottom: 0;
    align-items: center;
    z-index: 3000;
    width: 100%;
}
.leads-clear-button {
  width: 60px;
  margin: 11px 0px;
  font-weight: normal;
  border-radius: 5px;
  padding: 5px 5px;
  margin-right: 5px;
}

.leads-clear-btn , .leads-clear-button{
  font-size: 15px;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.rc-slider-track {
  background-color: #2463c7 !important;
}

.rc-slider-dot-active,
.rc-slider-handle {
  border-color: #2463c7 !important;
}

.pageVisited-leads {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    color: white;
    font-size: 11px;
    background-color: #0064ce;
    padding: 0 5px;
    width: 96px;
    height: 33px;
  }
  .ant-input {
    border-radius: 0 4px 4px 0;
    width: 86px;
    height: 33px;
  }
  .ant-input-group {
    height: 33px;
  }
  .ant-select-suffix {
    color: white;
    font-size: 9px;
    margin-left: 8px;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-select-selection {
    height: 15px;
  }

  .ant-select-open {
    .ant-select-suffix {
      transform: rotate(180deg);
    }
  }
}

.country-flag {
  height: 15px;
  width: 22px;
}

.leads-container-flag {
  width: 48px;
  min-height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.leads-modal {
  cursor: default;

  display: flex;
  align-items: center;
  justify-content: center;

  .MuiTableContainer-root {  
    padding-top: 0px !important;
    background: #ffffff;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    thead {
      height: 70px;
      background: #0064ce;
      border-radius: 4px 4px 0px 0px;
      padding-top: 15px;
      tr {
        p {
          font-weight: 400;
          font-size: 1.125rem;
          color: #fff;
        }
        h4 {
          font-weight: 500;
          font-size: 1.125rem;
          margin: 0;
          color: #fff;
        }
      }
    }

    svg {
      cursor: pointer;
    }

    tbody {
      span {
        font-size: 0.875rem;
        color: #575757;
      }
    }

    .add-new {
      border: none;
      .MuiAccordion-root {
        border-radius: 0;
        border-bottom: 1.25px solid #9e9e9e;
        box-shadow: none;
      }
      .MuiAccordionSummary-root {
        padding: 0;
      }
      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
  }
}

.table-add-min-width {
  min-width: 130px;
}
// specially for Sector and Industry fields
.table-add-min-width-150 {
  min-width: 150px;
}
.table-leads-text{
  font-weight: 600;
font-size: 12px;
line-height: 15px;
color: rgba(0, 0, 0, 0.4);

}
.table-prospects-text{
  font-family: 'Euclid';
    font-style: normal;
    font-weight: 400;
    font-size: 11.6773px;
    line-height: 15px;
    /* identical to box height */
    color: rgba(0, 0, 0, 0.4);
}

.leads-stats-connect-btn{
width: 130px;
height: 25px;
border: none;
color: white;
margin-left: -20px !important;

border-radius: 5px;
}
.prospect-stats-connect-btn {
  width: 130px;
  height: 25px;
  border: none;
  color: white;   
  border-radius: 5px;
}
.names{
  font-weight: bolder;
}


.leads-table-skiped{
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 15;
  padding: .5rem;
  color: white;

}

.leads-table-steps{
  .MuiTableRow-root {
    border: none !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > span{
    margin: 0 1.3rem;
  }
}

.cell-1{
  margin: 0 2rem 0 0;
}

.leads-table-stop{  
  background-color: white;
  border-radius: 4px;
  width: 53px;
  border: 0.2px solid #9E9E9E;
  box-shadow: 0px 3px 6px 0px #00000033;  
  height: 32px;
  color: #000000;
  margin:  0 3px;
}

.filter-loader {
  width: 230px !important; 
  margin-left: 17px !important;
}

/* icon container */

.icon-container {
  display: flex;
  justify-content: flex-start;
  padding: 3px 0;
  transform: translateX(-4.5px);
  gap: 2px;
  margin-top: -.7rem;
  svg {
    height: 19px;
  }
}

.domain-icon {
  padding: 0.5px;
}

.domain-icon:hover {
  color: #000081 !important;
  cursor: pointer;
  transition: transform 0.2s;
  transform: scale(1.1);
}

.linkedin-icon{
  color: #0077b5 !important;
  cursor: pointer;
}

.linkedin-icon:hover {
  transition: transform 0.2s;
  transform: scale(1.1);
}

.crunchbase-icon {
  margin: 0 5px;
}

.crunchbase-icon:hover {
  color: #0060ff !important;
  cursor: pointer;
  transition: transform 0.2s;
  transform: scale(1.1);
}

.twitter-icon:hover {
  color: #1DA1F2 !important;
  cursor: pointer;
  transition: transform 0.2s;
  transform: scale(1.1);
}

.linkedin-icon-small:hover{
  color: #0077b5 !important;
  cursor: pointer;
  transition: transform 0.2s;
  transform: scale(1.1);
}
