.workspace-settings,
.icp {
  .css-2b097c-container {
    width: 165px;
    // max-width: 230px;
    .css-g1d714-ValueContainer,
    .css-1hwfws3 {
      max-height: 77px;
      text-transform: capitalize;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .css-1rhbuit-multiValue {
      min-width: 64px;
      background: #e0e0e0;
      border-radius: 13px;
      font-weight: 300;
      color: #000000;
      justify-content: space-between;
      & > div {
        font-size: 11px;
      }
    }
    .css-tlfecz-indicatorContainer {
      padding: 4px;
    }
    .css-1gtu0rj-indicatorContainer {
      padding: 4px;
      transform: rotate(180deg);
    }
    .css-xb97g8 {
      &:hover {
        background-color: transparent;
        color: black;
        cursor: pointer;
      }
    }
  }
}
