$element-separator: '_';

$primary: #651bff;
$secondary: #40247a;
$tertiary: rgba(76, 17, 185, 0.27);

$white: #e7dff9;
$black: #05000f;

$dark: #322944;
$skeleton: #0a0121;
