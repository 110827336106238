@include block(button) {
  cursor: pointer;
  --icon-size: 30px;
  color: $white;
  @include perfect-transition;

  + .button {
    margin-left: 5px;
  }

  @include when(disabled) {
    color: $dark;
  }

  @include modifier(large) {
    --icon-size: 40px;

    + .button {
      margin-left: 30px;
    }
  }

  @include when(dark) {
    color: $dark;

    @include when(disabled) {
      color: $white;
    }
  }
}
