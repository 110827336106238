/*Sidebar Styles*/

.side-nav {
  width: $side-nav-width;
  background-color: $sidebar-bg !important;
  color: $sidebar-text-color !important;
  border-right: 0 none !important;
  border-left: none !important;
  margin-top: $app-bar-height;

  & .user-profile {
    background-color: $sidebar-bg-darken;
    padding: 13px 20px;
  }

  & .user-avatar {
    margin: 0 15px 0 0 !important;
    height: 44px !important;
    width: 44px !important;
    line-height: 44px;
  }

  & .user-detail {
    & .user-name {
      color: $sidebar-hover-color;
      cursor: pointer;
      font-size: $font-size-base;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}

.user-detail {
  & .user-name {
    cursor: pointer;
  }
}

.side-nav-menu {
  min-height: calc(100vh - 205px);
  font-size: $font-size-base;
  // background-color: #1A1B24;
  padding-top: 2.7rem;
  position: relative;
  top: 0px;
}

.side-nav-menu >div:nth-child(2) {
  border-bottom: 3px solid #fff;
  margin: 0 6px;
  margin-bottom: 6px;
  border-radius: 1px;
  padding-bottom: 6px;
}
.side-nav-menu >div:nth-child(4) {
  margin-top: 2px;
}

.nav-section {
  position: relative;

  & .nav-header {
    color: $sidebar-text-color;
    padding-bottom: 10px;
    font-size: 11px;
    text-transform: uppercase;
    margin-right: 20px;
    margin-left: 20px;
    header {
      background: #1A1B24;
      height: 50px;
    }
  }

  &:first-child {
    & .nav-header {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 0 none;
    }
  }

  & .nav-menu-item {
    padding: 0;
    & .nav-tabs {
      margin-bottom: .7rem;
      padding: .4rem;
      display: grid;
      border-bottom: none;
      place-content: center;
      background-color: #1A1B24;      
    }
  }
  

  & .nav-collapse,
  & .nav-menu-item {
    margin-bottom: 1px;
  }
}

.nav-collapse {
  position: relative;

  & &-btn {
    color: $sidebar-text-color;
    font-size: 14px;
    padding: 7px 30px 8px 20px;
    letter-spacing: 0.02857em;
    line-height: 1.75;
    &:focus,
    &:hover {
      background-color: $sidebar-bg-darken;
      color: $sidebar-hover-color;
    }
  }

  &.open {
    background-color: $sidebar-bg-darken;

    & .nav-collapse-btn {
      background-color: $sidebar-bg-darken;
      color: $sidebar-hover-color;

      &:focus,
      &:hover {
        background-color: $sidebar-bg-darken;
        color: $sidebar-hover-color;
      }
    }
  }

  & i,
  svg {
    margin-right: 12px;
    font-size: 15px;
  }

  & .nav-text {
    flex: 1;
  }

  & .nav-arrow {
    font-size: 16px;
  }
}
.nav-subtitle {
  padding-left: 31px;
  width: 230px !important;
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
}

.nav-details {
  color: $sidebar-text-color;
  font-size: 10px !important;
  display: flex;
  flex-wrap: wrap;
  &:hover, &.active, &:focus {
    color: #fff !important;
  }
}

.flex-text{
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-wrap: nowrap;
}

.nav-collapse {
  & .nav-menu-item .nav-menu-link {
    color: $sidebar-text-color;
    padding-left: 52px;
    @include border-right-radius($border-radius-xxl);
    margin-right: 20px;
    position: relative;

    &:focus,
    &:hover,
    &.active {
      background-color: $sidebar-bg-darken;
      color: $sidebar-hover-color;
    }

    &:after {
      content: '';
      position: absolute;
      // right: 15px;
      top: 50%;
      @include translateY(-50%);
      display: none;
      background-color: $white;
      width: 6px;
      height: 6px;
      @include border-radius($border-radius-circle);
    }

    &.active:after {
      display: inline-block;
    }
  }

  &.open {
    & .nav-menu-item .nav-menu-link.active {
      background-color: $sidebar-bg-darken-highlight;
      color: $sidebar-hover-color;
    }
  }

  & .nav-collapse {
    & .nav-collapse-btn {
      padding-left: 52px;
    }

    & .nav-menu-item .nav-menu-link {
      padding-left: 72px;
    }

    & .nav-collapse {
      & .nav-menu-item .nav-menu-link {
        padding-left: 92px;
      }

      & .nav-collapse-btn {
        padding-left: 72px;
      }
    }
  }
}
.MuiList-root.nav-menu-item.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px!important;
}

.nav-menu-item {
  height: 45px;
  font-family: 'Euclid', Roboto;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  & .nav-menu-link {
    color: #ffffff !important;
    font-size: 14px;
    white-space: normal;
    margin-right: 2px;

    // white-space: nowrap;
    padding: 8px 11px 9px 12px;
    letter-spacing: 0.02857em;
    line-height: 1.5;
    display: block;
    text-decoration: none;
    &:focus,
    &:hover,
    &.active {
      background:#000000;
      border-radius: 4px;
      color: #ffffff !important;
    }
    &.disabled {
      color: gray !important;
    }
  }

  & i,
  svg {
    // margin-right: 12px;
    font-size: 15px;
    width: 1.28571429em;
    text-align: center;    
  }
}

.app-container.mini-drawer {
  & .side-nav {
    @include transition($transition-base);
    width: $mini-drawer-width;

    &:not(:hover) {
      & .nav-collapse .nav-collapse-btn,
      & .nav-menu-item .nav-menu-link {
        padding: 12px 10px;
      }

      & .nav-section {
        & .nav-header {
          margin-left: 10px;
          margin-right: 10px;
        }

        &:not(:first-child) .nav-header {
          padding-top: 20px;
          margin-top: 10px;
        }
      }

      & .side-nav-menu .nav-text,
      & .side-nav-menu .nav-arrow,
      & .user-profile .user-detail,
      & .side-nav-menu .nav-collapse.open > .nav-collapse-item {
        display: none;
      }

      & .side-nav-menu .nav-section {
        text-align: center;
      }

      & .side-nav-menu .nav-section .nav-collapse-btn {
        text-align: center;
        justify-content: center;
      }

      & .side-nav-menu .nav-section i,
      & .side-nav-menu .nav-section .zmdi {
        margin-right: 0;
      }
    }
  }
}

.app-container.mini-drawer {
  & .side-nav:hover {
    width: $side-nav-width;
  }
}

.customizer {
  padding: 20px 35px;
  & img {
    padding: 5px;
    cursor: pointer;
  }
}

.user-info {
  & ul {
    & li {
      font-size: 15px;
    }
  }
}

.new-header {
  z-index: 4;
  position: fixed;
  top: 0;
  // left: 0;
  height: 50px;
  padding: 0 10px 0 17px;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: #1A1B24;
  width: 60px;
}

.websites{
  // background: #1E4EFF;
  background: #1A1B24;
  position: relative;
  padding-top: 5px;
  height: 45px;
  width: 100%;
  z-index: 200000000;
  display: flex;
  align-items: center;
  justify-items:center;
  cursor: pointer;
  padding-left: 7px;
  span {
    padding: 8px 12px 10px 13px;
    &:focus,
    &:hover,
    &.active {
      background: #000000;
      border-radius: 4px;
      color: #ffffff !important;
    }
  }

  .workspace-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    p {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    
        .workspace-url {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
           text-transform: capitalize;
            color: #FFFFFF;
          margin-bottom: 0;
          margin-top: .36rem;
        }
    
        .workspace-helper {
          
            font-style: normal;
            font-weight: 500;
            margin-top: .36rem;
            font-size: 12px;
            line-height: 1.5px;
          
            color: #9DCCFF;
        }
      }
      
  }
   
}
.help-center, .account-progress{
  display: flex;
  justify-content: center;
  align-items: center;
  // position: relative;
  height: 43px;
  z-index: 2000000;
  cursor: pointer;
  font-family: 'Euclid';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  // line-height: 18px;
  color: #fff;

  span {
    padding: 8px 11px 9px 12px;
    margin-right: 2px;
    &:focus,
    &:hover,
    &.active {
      background: #000000;
      border-radius: 4px;
      color: #ffffff !important;
    }
  }

  .workspace-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // flex-direction: column;


      .workspace-url {

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-transform: capitalize;
        color: #FFFFFF;
        margin-bottom: 0;
        margin-top: .36rem;
      }

    
    }
  }
     
  
}

.help-center {
  // background: #1A1B24;
  span {
    padding: 8px 12px 9px 13px;
  }
}

.new-footer {
  // background: #1A1B24;
  position: relative;
  height: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
  // padding-left: 20px;
  cursor: pointer;
  
  div {
    font-family: 'Euclid';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 2px;
      color: #FFFFFF;
  }
  span {
    font-family: 'Euclid';      
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #9C9C9C;
  }
  .avatar {
    width: 37px;
    height: 37px;
    border: 1px solid #fff;
    margin-right: 2px;
    &.sm {
      width: 25px;
      height: 25px;
      margin-left: -10px;
    }
  }
}

.new-popover {
  font-family: 'Euclid Medium', Roboto;
  width: 100%;
  padding: 2rem 1.25rem 1.5rem;
  max-width: 316px;

  background: #ffffff;
  box-shadow: -6px -6px 20px rgba(0, 0, 0, 0.1), 6px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  p,
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    color: rgba(#323232, 0.6) !important;
    &:hover,
    &:focus,
    &.active {
      background: rgba(#000000, 0.1);
      cursor: pointer;
    }
    span {
      margin-left: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 0.875rem;
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
  &_section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 8px;
    margin-bottom: 1.5rem;
    h5 {
      font-family: 'Euclid Bold', Roboto;
      font-weight: 400;
      font-size: 1rem;
      margin-bottom: 0;
      color: #000000;
    }
    small {
      margin-bottom: 10px;
      display: block;
      font-weight: 400;
      font-size: 0.875rem;
      color: rgba(#000, 0.6);
    }
  }
}

