//webhooks page

.spinner-webhooks-submit {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 5px 20px 5px;
}

.webhook-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 1117px) {
    flex-direction: column;
    %button-size-shared {
      width: 100%;
      height: 35px;
      font-size: small;
    }
    .webhooks-save-button {
      @extend %button-size-shared;
    }
    .webhooks-test-data-button {
      @extend %button-size-shared;
    }
  }
}

%webhook-button-shared {
  border-radius: 4px;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.26);
  box-sizing: border-box;
  width: 48%;
}
.webhooks-test-data-button {
  @extend %webhook-button-shared;
  background: #2296f3;
  border-color: #2296f3;
  font-size: larger;
  height: 48px;

  &:hover {
    background: #1560bd !important;
    border-color: #1560bd !important;
  }
}

.webhooks-save-button {
  @extend %webhook-button-shared;
  background-color: map_get($green, darken-1);
  border-color: map_get($green, darken-1);
  font-size: larger;
  height: 48px;
  &:hover:enabled {
    background: #008000 !important;
    border-color: #008000 !important;
  }
}

.ant-form-webhooks {
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  margin: 0px;
  @media only screen and (max-width: 700px) {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}
