/*setup-tracking-script Page Styles*/

.banner-setup-script-tracking {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;
  color: #2c4460;
  @media only screen and (max-width: 650px) {
    font-size: 23px;
    font-weight: 400;
    line-height: normal;
  }
}

.lower-section-banner-setup-tracking {
  font-size: 20px;
  line-height: 40px;
  font-weight: 400;
  @media only screen and (max-width: 650px) {
    font-size: 14px;
    line-height: normal;
  }
}

.disable-copy-setup {
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.go-back-setup-script-tracking {
  .MuiIconButton-root {
    font-weight: 400;
    font-size: 16px;
    color: map_get($gray, darken-1);
  }
}

.setup-tracking-script-tabs {
  @media (max-width: 600px) {
    .MuiTab-root {
      font-size: 0.675rem;
    }
  }

  .MuiTab-root {
    text-transform: none;
  }
}

.tab-content-container-setup-tracking {
  min-height: 300px;
  // padding: 15px;
  @media (max-width: 600px) {
    padding: 5px;
    .MuiTypography-body1 {
      font-size: 0.86rem;
    }
  }
}

.invite-member-setup-tracking {
  .MuiTypography-body1 {
    @media (max-width: 600px) {
      font-size: 0.867rem;
    }
  }
}

.copy-container-setup-tracking {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  .MuiButton-root {
    text-transform: none;
    background-color: map_get($green, darken-3);
    color: white;
  }
}

.validating-workspace-bottom-text {
  .MuiTypography-body1 {
    font-size: 0.867rem;
    color: map_get($gray, darken-1);
    @media (max-width: 600px) {
      font-size: 0.667rem;
    }
  }
}

.display-code-markdown-container {
  position: relative;
  .MuiButton-root {
    position: absolute;
    right: 0px;
    top: 0px;
    text-transform: none;
  }
}

.track-script-verify-button {
  width: 100%;
  height: 55px;
  text-transform: none;
  font-size: 1rem;
  margin: 0;
}

.track-script-input-verify-url {
  width: 69%;
  height: 55px;
  text-transform: none;
  font-size: 1.2rem;
  margin: 0;
  color: #343d52;
  font-weight: 500;
  border: 1px;
  border-style: solid;
  border-color: #89b9c5;
  &::placeholder {
    font-size: 18px;
  }
  @media (max-width: 600px) {
    font-size: 0.8rem;
    &::placeholder {
      font-size: 14px;
    }
  }
}

.track-script-verify-achor {
  text-decoration: none;
}

// .track-script-verify-container {
//   background: white;
//   border-radius: 15px;
// }

.track-script-textfield-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 59px;
}

.track-script-https-container {
  color: #909090;
  font-size: 1.2rem;
  display: flex;
  font-weight: 500;
  height: 55px;
  align-items: center;
  justify-content: center;
  width: 30%;
  background: #f4f4f7;
  border: 1px;
  border-right: 0;
  border-style: solid;
  border-color: #89b9c5;
  @media (max-width: 700px) {
    font-size: 0.8rem;
  }
}
// .track-script-https-container {
//   font-weight: normal;
//   font-size: 1rem;
//   height: 41px;
//   color: #fff;
//   background: #02c36f;
//   border: none;
//   border-radius: 0;
//   min-width: 102px;
//   position: relative;
//   & > span {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 8px;
//     svg {
//       transform: rotate(180deg);
//     }
//   }
//   div {
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 1;
//     span {
//       display: flex;
//       align-items: center;
//       justify-content: flex-start;
//       padding-left: 15px;
//       gap: 8px;
//       cursor: pointer;
//       height: 41px;
//       color: #797979;
//       background: #fff;
//       min-width: 102px;
//       border: 0.5px solid #9e9e9e;
//       &:last-child {
//         border: 0.5px solid #9e9e9e;
//       }
//       svg {
//         path {
//           fill: #797979;
//         }
//       }
//       &.active {
//         color: #fff;
//         background: #02c36f;
//         border: 0.5px solid transparent;
//         svg {
//           path {
//             fill: #fff;
//           }
//         }
//       }
//     }
//   }
// }


.track-script-tooltip-text, .tooltip-text {
  font-size: 14px;
}

.tooltip-text {
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    width: 200px;
    // background-color: #000;
    // padding: 5px;
  }
}

.track-script-verify-container {
 // margin-top: -10px !important;
 margin-top: 20px !important;
  padding: 15px;
  @media only screen and (max-width: 768px) {
    padding: 15px 0px;
  }
  .track-script-textfield-grid {
    min-height: auto;
  }
  .verify-container, .verify-container2 {
    background-color: #000 !important;
    color: #fff !important;
    border: none !important;
  }
  .verify-container {
    width: 111px;
    @media only screen and (max-width: 1100px) {
      width: 115px;
    }
  }
  .verify-container2, .verify-container3 {
    height: 55px !important;
  }
  .track-script-https-container {
    font-weight: normal;
    font-size: 1rem;
    height: 41px;
    color: #fff;
    background: #000;
    border: none;
    border-radius: 0;
    width: 142px;    
    position: relative;
    & > span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      svg {
        transform: rotate(180deg);
      }
    }
    div {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 15px;
        gap: 8px;
        cursor: pointer;
        height: 41px;
        color: #797979;
        background: #fff;
        width: 72px;
        border: 0.5px solid #9e9e9e;
        &:last-child {
          border: 0.5px solid #9e9e9e;
        }
        svg {
          path {
            fill: #797979;
          }
        }
        &.active {
          color: #fff;
          background: #02c36f;
          border: 0.5px solid transparent;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  .verify-website {
    margin-top: -19px;
    font-weight: normal;
    font-size: 1rem;
    height: 55px;
    color: #fff;
    background: #000;
    border: none;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    // border-radius: 0;
    min-width: 95px;
    padding-left: 10px;
    position: relative;
    @media screen and (min-width:770px) {
      margin-left: -15px;
    }
    & > span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      svg {
        transform: rotate(180deg);
        margin-top: 4px !important;
      }
    }
    div {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 9px;
        gap: 8px;
        cursor: pointer;
        height: 41px;
        color: #797979;
        background: #fff;
        width: 95px;
        border: 0.5px solid #9e9e9e;
        &:last-child {
          border: 0.5px solid #9e9e9e;
        }
        svg {
          margin-top: 4px !important;
          path {
            fill: #797979;
          }
        }
        &.active {
          color: #fff;
          background: #02c36f;
          border: 0.5px solid transparent;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .track-script-input-verify-url {
    border-color: #dadada;
    height: 41px;
    font-weight: 500;
    font-size: 1rem;
    color: rgba(87, 87, 87, 0.8);
    border-radius: 0;
    width: 100%;
    text-decoration: none !important;

    &::placeholder {
      font-size: 18px;
    } 
    .MuiInputBase-input {
      text-decoration: none !important;
      &::placeholder {
        color: #000 !important;
        font-size: 0.85rem;
        opacity: 0.6;
      } 
    } 
  }
  button {
    border-radius: 0;
    height: 41px;
  }
  .verify-url {
    // border-color: #dadada;
    margin-top: -19.2px;
    border: none;
    background-color: #fff;
    height: 55px;
   // font-weight: 500;
   // font-size: 1rem;
   // color: rgb(87, 87, 87);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    // min-width: 300px;
    width: 305px;
    padding-left: 10px;
    ::placeholder {
      font-size: 14px;
      color: rgb(24, 22, 22);
      font-family: Roboto;
    }
  }
}

.iframe-setup-tracking-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
