.fill {
  width: 100%;
  height: 100%;
}

.lr-center {
  display: flex;
  align-items: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
