.install-tracker {
  min-height: 140vh;
  & > header {
    text-align: center;
    margin-bottom: 25px;

    h2 {
      font-weight: 500;
      font-size: 2rem;
      color: #2c4460;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.125rem;
      color: #5b6b7e;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 1024px) {
      h2 {
        font-weight: 500;
        font-size: 2.25rem;
      }
      p {
        font-weight: normal;
        font-size: 1.125rem;
      }
    }
    @media only screen and (max-width: 768px) {
      text-align: left;
      h2 {
        margin-bottom: 15px;
        font-size: 2rem;
      }
      p {
        font-size: 1rem;
      }
    }
    @media only screen and (max-width: 480px) {
      h2 {
        font-size: 1.5rem;
        margin-bottom: 12px;
      }
    }
  }

  &__main {
    width: 100%;
    max-width: 1145px;
    margin: auto;
  }

  &__container {
    margin-top: 70px;
    background: #ffffff;
    box-shadow: 0px 3px 20px -6px rgba(0, 0, 0, 0.25);
    header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      width: 100%;
      padding: 30px 0;

      background: #f6f8fc;
      h2 {
        font-weight: 500;
        font-size: 1.75rem;
        color: #575757;
        margin-bottom: 0;
      }
      p {
        font-weight: normal;
        font-size: 1rem;
        color: #625c5c;
        width: 90%;
        margin: auto;
        margin-bottom: 0;
      }
    }

    .track-script-verify-container {
      padding: 15px;
      @media only screen and (max-width: 768px) {
        padding: 15px 0px;
      }
      // @media only screen and (max-width: 600px) {
      //   padding-top: 20px;
      // }
      .track-script-textfield-grid {
        min-height: auto;
      }
      .track-script-https-container {
        font-weight: normal;
        font-size: 1rem;
        height: 41px;
        color: #fff;
        background: #000;
        border: none;
        border-radius: 0;
        min-width: 102px;
        position: relative;
        & > span {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          svg {
            transform: rotate(180deg);
          }
        }
        div {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 15px;
            gap: 8px;
            cursor: pointer;
            height: 41px;
            color: #797979;
            background: #fff;
            min-width: 102px;
            border: 0.5px solid #9e9e9e;
            &:last-child {
              border: 0.5px solid #9e9e9e;
            }
            svg {
              path {
                fill: #797979;
              }
            }
            &.active {
              color: #fff;
              background: #02c36f;
              border: 0.5px solid transparent;
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
      .verify-container {
        background-color: #000 !important;
        color: #fff !important;
      }
      .track-script-input-verify-url {
        border-color: #dadada;
        height: 41px;
        font-weight: 500;
        font-size: 1rem;
        color: rgba(87, 87, 87, 0.8);
        border-radius: 0;
        width: 100%;
      }
      button {
        border-radius: 0;
        height: 41px;
      }
    }

    @media only screen and (max-width: 480px) {
      header {
        h2 {
          font-weight: 500;
          font-size: 1.25rem;
        }
      }
    }
  }

  .technical-script {
    width: 55%;
    .tab-content-container-setup-tracking {
      padding: 0;
      min-height: auto;
      .display-code-markdown-container {
        margin-bottom: 20px;
        margin-left: 15px;
        width: 145%;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
      .text {
        margin-bottom: 1rem;
        margin-left: 1rem;
        position: relative;
        font-weight: 300;
        .num {
          font-size: 1.125rem;
          color: #fff;
          background: #2296f3;
          width: 24px;
          height: 28px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          position: absolute;
          left: -2.3rem;
          @media only screen and (max-width: 480px) {
            left: -2rem;
            width: 20px;
            height: 24px;
            font-size: 1rem;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .technical-video {
    position: relative;
    width: 45%;
    iframe {
      width: 100%;
      height: 100%;
      max-height: 212px;
    }
    a {
      font-size: 1rem;
      color: #429ed4;
      margin-bottom: 1rem;

      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: flex-end;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      iframe {
        max-height: none;
        height: 300px;
      }
    }
  }

  &__radiogroup {
    gap: 20px;
    flex-wrap: nowrap !important;
    max-width: 920px;
    width: 100%;
    justify-content: space-between;
    margin: auto;

    @media only screen and (max-width: 750px) {
      flex-wrap: wrap !important;
    }
  }

  &__label {
    width: 100%;
    max-width: 430px;
    min-width: 430px;
    height: 100px;
    margin: 0 !important;

    border: 1px solid #dadada;
    border-radius: 8px;
    justify-content: center;
    &.active {
      background: rgba(33, 150, 243, 0.1);
      border: 1px solid #0c00ff;
    }

    @media only screen and (max-width: 1024px) {
      min-width: auto;
    }

    @media only screen and (max-width: 750px) {
      max-width: none;
      justify-content: flex-start;
      padding: 15px;
      min-height: 100px;
      height: auto;
    }
  }

  &__technical {
    margin-left: 10px;
    h3 {
      font-weight: 500;
      font-size: 1.5rem;
      color: #495b70;
      margin-bottom: 0;
    }
    p {
      font-size: 1rem;
      color: #625c5c;
      margin-bottom: 0;
    }
    @media only screen and (max-width: 480px) {
      margin-left: 0;
      h3 {
        font-size: 1.25rem;
        font-weight: 500;
      }
      p {
        font-size: 0.875rem;
      }
    }
  }

  .calendly-inline-widget {
    min-width: auto !important;
    padding-top: 20px;
    height: 730px !important;

    body {
      background-color: #02c36f;
    }
  }
}
.gmail-connect-video {
  width: 750px;
  height: 500px;
  // background: #e2e8f0;
  margin-top: -4rem;
  border-radius: .3rem;
  padding: .4rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}
