.jr-visit-col {
  order: 1;

  @media screen and (max-width: 991px) {
    order: 2;
    margin-top: 20px;
  }

  .jr-visit-col-text {
    font-weight: bold !important;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    margin-top: -20px;
  }
}

.jr-audi-col {
  align-items: flex-end;
  order: 1;

  @media screen and (max-width: 991px) {
    order: 1;
  }

  @media screen and (max-width: 600px) {
    margin-top: 10px;
  }
}

.jr-wel-ema {
  position: relative;
}

.jr-task-list {
  position: relative;
}
