//workspace page
%verification-shared {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
}
.not-verified-websites {
  @extend %verification-shared;
  color: #f38622;
}

.verified-websites {
  @extend %verification-shared;
  color: #02c36f;
}
.gdpr-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-weight: 500;
    font-size: 0.875rem;
    color: #696969;
    margin: 0;
  }
  p {
    font-weight: 300;
    font-size: 0.625rem;
    color: #696969;
    margin: 0;
  }
}

.dropdown-email-digest {
  .dropdown-menu {
    min-width: 2rem !important;
    margin-top: 0px !important;
  }
}

.small-menu-email-digest {
  .dropdown-menu {
    min-width: 5rem !important;
    margin-top: 0px !important;
  }
}
