.banner-primary-section {
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;
  color: #2c4460;
  @media only screen and (max-width: 650px) {
    font-size: 23px;
    font-weight: 400;
    line-height: normal;
  }
}

.banner-secondary-section {
  font-size: 20px;
  line-height: 40px;
  font-weight: 400;
  @media only screen and (max-width: 650px) {
    font-size: 14px;
    line-height: normal;
  }
}

.go-back-icon-banner {
  .MuiIconButton-root {
    font-weight: 400;
    font-size: 16px;
    color: map_get($gray, darken-1);
    border-radius: 6px;
    svg {
      width: 20px;
      margin-right: 5px;
    }

    &:hover {
      svg {
        animation: shakeRight alternate 500ms linear;
      }
    }
  }
  //   @media only screen and (max-width: 650px) {
  //     .MuiIconButton-root {
  //       font-weight: 400;
  //       color: map_get($gray, darken-1);
  //     }
  //   }
}

.relative {
  position: relative;
}

.cur-pointer {
  cursor: pointer;
}

.cur-default {
  cursor: default;
}

.react-select-members {
  display: flex;
  flex-direction: row;
  width: 100;
  z-index: 1;
}

.freemium-appbar, .expired-trial-appbar {
  color: white;
  background: #eb5757;
  min-height: 40px;
  display: flex;
  align-items: center;
  // padding: 5px;
}
.freemium-appbar {
  padding: 0 30px;
  font-size: 0.9rem;
  margin-left: 60px;
  position: absolute;
  z-index: 30;
  width: calc(96.15vw - 1px);
  overflow-y: hidden;
  justify-content: space-between;
  @media only screen and (max-width: 770px) {
    padding-left: 20px;
    width: calc(88.5vw - 1px);

  }
}

.expired-trial-appbar {
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 5px;
  font-size: 1.05rem;
  @media only screen and (max-width: 770px) {
    font-size: 14px;
    line-height: normal;
    flex-direction: column;
  }
  .MuiSvgIcon-root {
    font-size: 1.05rem;
  }
}

.info-trial {
  color: white !important;
  text-decoration: underline;
  cursor: pointer !important;
}

.info-icon-trial {
  color: white;
}

@keyframes app-rotation-animation {
  0% {
    transform: rotateY(0);
  }
  25% {
    transform: rotateY(45%);
  }
  75% {
    transform: rotateY(-0.2turn);
  }
  100% {
    transform: rotateY(3.142rad);
  }
}

.rotation-animation-progress-hleads {
  -webkit-animation: app-rotation-animation 2s infinite; /* Safari 4+ */
  -moz-animation: app-rotation-animation 2s infinite; /* Fx 5+ */
  -o-animation: app-rotation-animation 2s infinite; /* Opera 12+ */
  animation: app-rotation-animation 2s infinite; /* IE 10+, Fx 29+ */
}

.image-progress-general {
  background-image: url('/images/app/images/logo-min.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 110px;
  height: 110px;
  background-position: center;
}

.loading-text-progress {
  width: 150px;
  position: absolute;
  top: 15px;
  left: -15px;
  background-color: transparent;
  background: transparent;
  color: #fff;
  font-size: 14px;
}
