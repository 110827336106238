/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: calc(100% - 50px);
  left: 50px;
  position: relative;
}

.app-wrapper {
  padding: 30px;
  width: 100%;
  // overflow-y: auto;
  max-height: 90vh !important;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
}
.quotastatus{
  font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    color: #000000;
}
.page-header{
    font-family: 'Euclid', Roboto;
    font-size: Max(15px, 1.25vw);
    font-weight: 600;
    width: 100vw;
    // height: Max(100px, 6vw);
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: fixed !important;    
    margin-left: -50px;
    margin-top: -60px;
    z-index: 1;
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(73, 73, 73, 0.1) !important;
  
    @media screen and (max-width: 572px) {
      margin-top: -40px;
      padding-left: 60px;
    }
}


.page-header-no-notif {
  font-family: 'Euclid', Roboto;
  font-size: Max(15px, 1.25vw);
  font-weight: 600;
  width: 100vw;
  height: Max(50px, 3vw);
  background: #ffffff;
  display: flex;
  align-items: center; 
  position: fixed !important;
  margin-left: -50px;
  margin-top: -60px;
  padding-left: 3rem;

  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(73, 73, 73, 0.1) !important;

  @media screen and (max-width: 572px) {
    margin-top: -40px;
    padding-left: 60px;
  }
}