.comment-placeholder-lead {
  &::placeholder {
    color: #e0e0e0;
    font-size: 12px;
  }
}

.scrollbar-comment-lead {
  width: 100%;
  max-height: 20em;
  overflow-y: auto;
  padding-right: 13px;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar:horizontal {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: #e0e0e0;
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .form-control:focus {
    border-color: #ced4da;
    border: 1px solid #ced4da;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #fff;
    border: 0.1px solid rgba(0, 0, 0, 0.3);
    //-webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.3) !important;
  }
}

.comment-form-focus {
  .form-control:focus {    
    box-shadow: none;
  }
}

.widget-comments {
  padding-right: 13px !important;
}

.date-comment {
  position: absolute;
  top: -20px;
  left: -70px;
  min-width: 110px;
  height: 18px;
  padding-left: 2px;
  padding-right: 2px;
  color: #343a40;
  font-size: 11px;
  background: #d9dddc;
  display: none;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
  background: #e0e0e0;
  //border: 0.1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
}

.dateContainer-comment {
  z-index: 160;
}

.showDate-comment {
  &:hover {
    .date-comment {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}
