@include block(message) {
  > div {
    background-color: $secondary;
  }

  @include element(content) {
    display: flex;
    align-items: center;
  }

  @include element(text) {
    margin-left: 5px;
  }
}
