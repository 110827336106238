/*Dashboard Styles*/
.box {
  position: relative;

  .box-top,
  .box-bottom {
    height: 100px;
    padding: 32px 15px;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: $display1-weight;
    .size-h5 {
      font-size: 24px;
      margin-left: 2px;
    }
    .material-icons {
      font-size: 40px;
      line-height: 40px;
    }
  }

  .box-bottom {
    border-top: 1px solid rgba($black, 0.15);
  }

  .box-info {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -12px;
    text-align: center;

    span {
      height: 24px;
      display: inline-block;
      padding: 4px 10px;
      text-transform: uppercase;
      line-height: 14px;
      background-color: $white;
      border: 1px solid rgba($black, 0.15);
      font-size: 12px;
      color: $text-muted;
      border-radius: 1em;
      .material-icons {
        line-height: 14px;
        font-size: 11px;
      }
    }
  }
}

.contact-list {
  margin-bottom: 14px;

  & i {
    margin-right: 14px;
    color: theme-color('primary');
  }

  & .media {
    margin-bottom: 18px;

    @media screen and (max-width: 1199px) {
      margin-bottom: 10px;
    }
  }
}

.temp-section {
  padding-top: 40px;
  @include display-flex(flex, row, wrap);
  @include justify-content(center);

  .temp-point,
  .detail-icon {
    font-size: 45px;

    @media screen and (max-width: 991px) {
      font-size: 35px;
    }

    @media screen and (max-width: 575px) {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 991px) {
    padding-top: 25px;
  }

  @media screen and (max-width: 575px) {
    padding-top: 15px;
  }
}

.assignment-table {
  & tbody tr:nth-child(odd) {
    background-color: $gray-300 !important;
  }
  & td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.status-cell {
  min-width: 100px;
  max-width: 120px;
  padding-right: 24px !important;
  & .badge {
    min-width: 70px;
  }
}

.thumbnail-list {
  margin-bottom: 0;

  & .thumbnail-item {
    padding: 0 2.5px;
    display: inline-block;
    width: 33.333%;
    margin-bottom: 5px;

    @media screen and (max-width: 767px) {
      width: 50%;
    }

    @media screen and (max-width: 575px) {
      width: 33.333%;
    }

    & img {
      max-width: 100%;
    }
  }

  & .grid-thumb-equal {
    @media screen and (max-width: 1368px) {
      padding-bottom: 91%;
    }

    @media screen and (max-width: 575px) {
      padding-bottom: 60%;
    }
  }
}

.post-list {
  & .post-image .grid-thumb-equal {
    padding-bottom: 100%;
  }
}

/*Ecommerce Dashboard Styles*/
.chart-f30 {
  font-size: 30px;

  @media screen and (max-width: 1366px) {
    font-size: 24px;
  }

  @media screen and (max-width: 1099px) {
    font-size: 20px;
  }
}

.chart-f20 {
  font-size: 20px;

  @media screen and (max-width: 1099px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1099px) {
    font-size: 16px;
  }
}

.chart-order {
  & .col-5 {
    order: 2;
    text-align: center;

    @media screen and (max-width: 575px) {
      order: 1;
    }
  }

  & .col-7 {
    order: 1;

    @media screen and (max-width: 575px) {
      order: 2;
    }
  }
}

.tab-notifications {
  margin-left: -$jr-card-margin;
  margin-right: -$jr-card-margin;
  margin-top: -10px;
  border-bottom: 1px solid $gray-500;

  @media screen and (max-width: 575px) {
    margin-left: -20px;
    margin-right: -20px;
  }

  @media screen and (max-width: 567px) {
    button,
    button * {
      font-size: 12px !important;
    }
  }
}

.chart-title {
  & h2 {
    font-size: 22px;
    margin-bottom: 5px;
  }
}

.img-fluid {
  width: 100%;
}

.comment-footer {
  & button {
    margin-bottom: 6px;
  }
}

/*Intranet Dashboard Styles*/
.net-chart {
  padding-left: 80px;
  padding-right: 15px;
  min-height: 100px;
  position: relative;
  overflow: hidden;
  @include display-flex();
  @include align-items(center);

  & .jr-card-thumb {
    margin: 0;
    width: $size-50;
    height: $size-50;
    color: $white;
    font-size: 20px;
    @include border-radius($border-radius-circle);
    @include display-flex(flex, column, nowrap);
    @include justify-content(center);
    @include align-items(center);
    @include box-shadow(
      0 6px 20px 0 rgba($gray-900, 0.19),
      0 8px 17px 0 rgba($gray-900, 0.2)
    );
    @include transition(all 100ms ease);
    position: absolute;
    left: 15px;
  }

  &:hover .jr-card-thumb {
    width: 70px;
    height: 100%;
    font-size: 30px;
    @include border-radius(0);
    position: absolute;
    left: 0;
  }
}

.jr-chart-or {
  & .chart-header {
    padding-bottom: 0;
  }
}

.net-chart-hr {
  @include display-flex();
  @include align-items(center);
  min-height: ($size-120 - 2);
  overflow: hidden;
  padding: 0;

  & .jr-card-thumb {
    margin: 0;
    min-width: $size-100;
    align-self: stretch;
    @include border-radius(0 $border-radius-circle $border-radius-circle 0);

    &-img {
      @include display-flex(flex, row, row);
      @include align-items(center);
      @include justify-content(center);
      height: 100%;

      & img {
        @include border-radius(50%);
        width: ($size-60 + 5);
      }
    }
  }

  & .grid-thumb-equal {
    padding-bottom: 130%;
  }

  & .jr-card-body {
    width: calc(100% - #{$size-100});
    padding: 12px;
  }
}

.user-detail-card {
  position: relative;
  @include display-flex();
  overflow: hidden;
  padding: 0;
  min-height: 140px;
  min-width: 180px;

  & .user-img {
    display: inline-block;
    width: 62px;
    height: 62px;
    @include box-shadow($avatar-shadow);
    @include border-radius($border-radius-circle);
  }

  & .user-img-container {
    content: '';
    @include display-flex(flex, row, nowrap);
    @include justify-content(flex-end);
    @include align-items(center);
    width: 160px;
    height: 160px;
    @include border-radius($border-radius-circle);
    padding: 18px;
    background-color: map_get($blue, base);
    position: absolute;
    top: 50%;
    left: -62px;
    @include translateY(-50%);
  }

  & .jr-card-body {
    padding: 20px 10px 20px 110px;
  }
}

.user-profile {
  padding: 10px;
  width: 270px;
}

.mh-120 {
  min-height: $size-120;
}

.jr-card-header-top {
  @include display-flex();
  @include align-items(center);
  margin-left: -12px;
  margin-right: -12px;

  @media screen and (max-width: 575px) {
    margin-left: -6px;
    margin-right: -6px;
  }
}

.jr-card-header-color {
  padding: 0px $jr-card-padding 5px;
  margin: (-$jr-card-padding) (-$jr-card-padding) 10px;
  @include border-radius($border-radius $border-radius 0 0);
  position: relative;

  @media screen and (max-width: 575px) {
    padding: 5px 20px 5px;
    margin: (-20px) (-20px) 5px;
  }

  & .jr-badge-up {
    @include display-flex(flex, row, nowrap);
    @include align-items(center);
    @include justify-content(center);
    width: 45px !important;
    height: 45px !important;
    padding: 5px;
    font-size: ($font-size-lg + 0.25rem);
    color: $white;
    @include border-radius($border-radius-circle);
    position: absolute;
    right: $size-20;
    @include box-shadow($avatar-shadow);
    bottom: -22px;
    z-index: 1;

    & .zmdi-mail-send {
      padding-left: 4px;
    }

    @media screen and (max-width: 1366px) {
      width: 40px !important;
      height: 40px !important;
      font-size: ($font-size-lg + 0.125rem);
      bottom: -20px;
    }
  }
}

.markt-table {
  @media screen and (max-width: 1366px) {
    .user-detail .user-description {
      font-size: $font-size-sm;
    }
  }
}

.todo-cell-group {
  & .todo-cell {
    margin-left: -5px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.social-list-line {
  position: relative;

  &:not(:last-child) {
    & .media-body {
      margin-bottom: 24px;
    }

    &:before {
      border-left: solid 1px $gray-500;
      content: '';
      left: 20px;
      position: absolute;
      top: 5px;
      bottom: 0;
      width: 1px;
      z-index: 1;
    }
  }
}

.list-line-item {
  position: relative;
  padding-left: 35px;
  margin-left: 40px;
  &:not(:last-child):before {
    border-left: solid 2px $gray-300;
    content: '';
    left: 15px;
    position: absolute;
    top: -30px;
    bottom: -25px;
    width: 1px;
    z-index: 1;

    @media screen and (max-width: 575px) {
      top: -20px;
    }
  }
}

.list-line-badge {
  position: absolute;
  left: 11px;
  top: 15px;
  z-index: 2;
  @include border-radius(50%);
  height: 10px;
  width: 10px;
  background-color: $gray-500;
}

.jr-revenue-chart {
  & .recharts-default-legend {
    text-align: left !important;
    margin-bottom: 20px !important;
  }
}

.user-profile-border {
  padding-left: $jr-card-margin;
  padding-right: $jr-card-margin;

  @media screen and (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $gray-200;
  }
}

.table-userdetail-mmin,
.daily-feed-body {
  margin-top: -15px;
}

.daily-feed-body {
  & .user-profile {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  & + .daily-feed-footer {
    border-top: 1px solid $gray-200;
    padding: 12px $jr-card-margin;
  }
}

.jr-comments {
  & .media-list {
    padding-left: $jr-card-margin;
    padding-right: $jr-card-margin;
    padding-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 0;

    &:hover {
      background-color: lighten($app-primary, 45%);
    }
  }
}

.team-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  @include display-flex(flex, row, nowrap);

  & li:not(:first-child) {
    margin-left: -6px;
  }

  & .user-avatar {
    margin-right: 0;
    cursor: pointer;
    position: relative;
    z-index: 1;
    @include transition($transition-base);

    &:not([class*='sieze-']) {
      width: 25px !important;
      height: 25px !important;
    }

    &:hover,
    &:focus {
      z-index: 2;
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}

.chart-user-statistics {
  margin: -128px -30px 30px;
  padding: 128px 0 0;
  @include border-radius(0);
  & .jr-card-header {
    padding: 30px 30px 0;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 575px) {
    margin-top: -142px;
    padding-top: 142px;
  }
}

.statistics-chartjs {
  & .chartjs-render-monitor {
    height: 240px !important;

    .misc-statistics-chartjs & {
      height: 300px !important;
    }

    @media screen and (max-width: 1919px) {
      height: 280px !important;

      .misc-statistics-chartjs & {
        height: 220px !important;
      }
    }

    @media screen and (max-width: 991px) {
      height: 200px !important;
    }
  }
}

.jr-card-timer-header,
.jr-card-timer-avatar {
  margin-bottom: 6px;

  @media screen and (max-width: 1919px) {
    margin-bottom: 40px;

    .wid-classic-timer & {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 16px;
  }
}

.jr-card-notifications {
  & .user-profile {
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.lead-direct-traffic {
  font-size: 14px;
}

.funnel-flag {
  position: relative;
  .leads-flag {
    position: relative;
    position: relative;
    top: -1px;
    margin-left: 15px;
  }
  &:hover,
  &:active,
  &:focus {
    p {
      opacity: 1;
    }
  }
  p {
    display: flex;
    align-items: center;

    opacity: 0;
    position: absolute;
    bottom: 22px;
    z-index: 2;

    margin: 0;
    height: 27px;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.39998px;
    white-space: nowrap;

    color: #ffffff;
    background: #4f4d4d;
    border: 1px solid #ffffff;
    padding: 0 5px;
  }
}

.lead-line-direct-traffic {
  border-left: solid 2px $gray-300;
  content: '';
  position: absolute;
  left: -20px;
  top: 0px;
  bottom: -80px;
  width: 1px;
}

.lead-small-white {
  width: 6px;
  height: 18px;
  position: absolute;
  top: 0px;
  left: -21px;
  background: #fff;
  z-index: 1;
}

.lead-right-arrow {
  border-color: transparent #0c00ff;
  border-style: solid;
  border-width: 2px 0px 2px 2px;
  height: 0px;
  width: 0px;
  position: absolute;
  top: 7px;
  right: -3px;
  transform: scale(3, 1);
}

.lead-line-user {
  .list-line-item:not(:last-child):before {
    border-left: solid 2px #dee2e6;
    content: '';
    left: 15px;
    position: absolute;
    top: -3px;
    bottom: -70px;
    width: 1px;
    z-index: 1;
  }
  &:hover {
    .tooltip-accuracy {
      display: block;
      position: absolute;
      top: 0px;
      left: 10;
      background: #4f4d4d;
      color: #fff;
      z-index: 5;
      padding: 5px;
      font-size: 12px;
    }
  }
}

.tooltip-accuracy {
  display: none;
}

.lead-user-line {
  border-left: solid 2px #dee2e6;
  height: 100px;
  position: absolute;
  top: 0px;
  left: -20px;
  background: #dee2e6;
  z-index: 2;
}

.list-line-user-content {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0px;
  left: -39px;
  z-index: 4;
}

.lead-list-spacer {
  min-height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .name-title-container {
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    .list-name {
      font-size: 13px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .list-role {
      font-size: 14px;
      color: #9e9e9e;
      display: -webkit-box;
      height: 45px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.625;
    }
  }
}

.nav-user-status-onboarding {
  width: 100%;
  height: 15px;
}
