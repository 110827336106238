/* Pages

   ========================================================================== */
@import '~antd/dist/antd.css';
//the body css below overrides white background implemented by antd to match theme
body {
  background-color: #f4f4f7;
  overflow: auto !important;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

@import 'login';
@import 'error';
@import 'editor';
@import 'contact';
// @import "portfolio";
// @import "faq";
@import 'dashboard';
@import 'classic-dashboard';
// @import "dropzone";
@import 'leads';
@import 'setup-script-tracking';
@import 'onboarding';
@import 'websites';
@import 'webhooks';
@import 'classic-components';
@import 'app-module';
@import 'invite';
@import 'iframe';
@import 'profile';
@import 'edit-profile';
@import 'comments';
@import 'install-tracker';
@import 'workspace-settings';
@import 'integrations';
