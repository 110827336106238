// onboarding page

.form-wrapper-onboarding {
  width: 100%;
  margin: 0 auto;
}



.ant-form-onboarding {
 
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  margin: 0px;
  @media only screen and (max-width: 650px) {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}
.ant-form-item-explain-error {
  // color: #d40404 !important;
  color: #fff !important;
  
  
}
.ant-form-item {
  margin-bottom: 20px;
}

.spinner-onboarding-submit, .spinner-onboarding {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.spinner-onboarding {
  color: #fff !important;
  .ant-spin-dot-item {
    background-color: #fff !important;
  }
}

.get-started-button {
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  background-color: #2296f3;
  border-color: #2296f3;
  font-size: 25px;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 58px;
  @media only screen and (max-width: 650px) {
    width: 100%;
    height: 52px;
  }
}

.secondary-banner-onboard {
  .MuiTypography-body1 {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    color: #485a6f;
    text-align: center;
    @media only screen and (max-width: 650px) {
      font-size: 1rem;
      line-height: normal;
    }
  }
}

.input-onboarding {
  
  margin-top: 5px;
  border-radius: 5px;
  width: 100%;
  background: #fff;
  margin-bottom: 2px;
  height: 69px;
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
}

.intl-tel-input {
  height: 60px;
  width: 100%;
  :focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(64, 169, 255, 0.4);
    box-shadow: 0 0 0 2px rgba(64, 169, 255, 0.4);
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  :hover {
    border-color: #40a9ff;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #c0c0c0;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #6c757d;
    font-size: 1rem;
  }

  ::placeholder {
    color: #6c757d;
    font-size: 0.97rem;
  }

  .flag-container .allow-dropdown .selected-flag {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .allow-dropdown .flag-container:focus .selected-flag {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    border-radius: 5px !important;
    -webkit-box-shadow: 0 0 0 0.5px rgba(64, 169, 255, 0.4);
    box-shadow: 0 0 0 0.5px rgba(64, 169, 255, 0.4);
    -webkit-border-top-left-radius: 5px !important;
    -webkit-border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .selected-flag {
    border: none !important;
    box-shadow: none !important;
  }
}

.intl-tel-input-onboarding {
  height: 60px;
  width: 100%;
  margin-top: 5px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 2px;
  border: 1px solid #d9d9d9;
  .flag-container .allow-dropdown .selected-flag {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 14px;
  }
}

.intl-input-onboarding-skeleton {
  width: 100%;
  height: 60px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  :hover {
    border-color: #40a9ff;
  }
  :focus {
    border-color: #40a9ff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(64, 169, 255, 0.4);
    box-shadow: 0 0 0 2px rgba(64, 169, 255, 0.4);
  }
  overflow: hidden;
}

.iframe-container-onboarding {
  width: 100%;
  height: 120vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 1100px) {
    height: 120vh;
  }

  @media (max-width: 844px) {
    height: 140vh;
  }
  @media (max-width: 588px) {
    height: 140vh;
  }
  @media (max-width: 281px) {
    height: 140vh;
  }
  .iframe-onboarding {
    flex-grow: 1;
    border: none;
    padding: 0;
    margin: 0;
  }
}

.text-onboarding-banner-n {
  padding-top: 15px;
  color: #2c4460;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
}

.onboarding-paper-n {
  background: #f6f8fc;
  border: 0.5px solid #dadada;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.onboarding-app-wrapper {
  padding: 0px;
  position: fixed;
  // top: 110px;
  left: 0;
  z-index: 4;
}

.onboarding__testimonial {
  display: flex;
  justify-content: space-between;
  gap: 2%;
  padding-left: 4%;
  font-family: 'Poppins', sans-serif;
  max-width: 522px;
  &__avatar {
    display: flex;
    align-items: center;
    width: 47%;
    img {
      border-radius: 50%;
    }
    .text {
      margin-left: 10px;
      h4 {
        font-weight: 600;
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 0;
      }
      p {
        font-weight: 500;
        font-size: 0.65rem;
        margin-bottom: 0;
        color: rgba(93, 93, 93, 0.8);
      }
    }

    @media only screen and (max-width: 480px) {
      width: auto;
    }
  }
  &__testimony {
    font-style: italic;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 150%;
    color: #0a0a0a;
    width: 51%;

    @media only screen and (max-width: 480px) {
      width: auto;
      padding-top: 5px;
    }
  }

  @media only screen and (max-width: 960px) {
    padding-left: 0;
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
