.edit-profile {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  &__banner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    span {
      font-size: 1.5rem;
      color: #575757;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }

    h2 {
      font-weight: bold;
      font-size: 2.625rem;
      color: #2c4460;
      margin: 0;
      margin-left: 10%;
    }
  }

  &__form {
    background: #f6f8fc;
    border: 0.5px solid #dadada;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    padding: 50px 10%;
    height: 574px;
    &__head {
      font-weight: normal;
      font-size: 1.25rem;
      text-align: center;
      color: #5b6b7e;
      margin-bottom: 25px;
    }
  }

  .input-onboarding {
    border-radius: 5px;
    margin-bottom: 10px;
    border: 2px solid transparent !important;
    &:hover,
    &:focus,
    &:active {
      border: 2px solid #60d3bd !important;
      box-shadow: none;
    }
  }

  .form-extra-space {
    height: 94px;
  }

  label {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  @media only screen and (max-width: 650px) {
    h2 {
      font-weight: 500;
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 420px) {
    h2 {
      font-weight: 500;
      font-size: 1.5rem;
      margin-left: 6%;
    }
  }
}
