.iframe-container {
  width: 100%;
  height: 160vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 1100px) {
    height: 220vh;
  }

  @media (max-width: 844px) {
    height: 350vh;
  }
  @media (max-width: 588px) {
    height: 380vh;
  }
  @media (max-width: 281px) {
    height: 440vh;
  }
  .iframe-upgrade {
    flex-grow: 1;
    border: none;
    padding: 0;
    margin: 0;
  }
}
